<template>
  <div>
    <div>
      <div
        style="background-color: #e0ffff; text-align: center; font-size: 18px"
      >
        В режиме тестирования
      </div>
      <div
        style="
          padding: 20px;
          display: flex;
          flex-wrap: wrap;
          justify-content: center;
        "
      >
        <div style="width: 100%"></div>
        <details style="text-align: auto; width: 700px">
          <summary>Инструкция</summary>
          <div>
            Для создания карточки нарушения необходимо заполнить все поля.
            <br />
            <b>Нарушение на класс:</b>
            выбирайте класс, не указывайте ученика <br />
            <b>Нарушение на группу учеников:</b>
            Добавляйте учеников (можно с разных классов) с помощью кнопки
            "Добавить ученика"
            <br />
            <br />
            Статус заявки может изменять и классный руководитель! Но обязательно
            оставляйте комментарий, что конкретно было сделано или причину
            изменения статуса!
            <br />
            <b>"Совет профилактики":</b> если Вы не можете самостоятельно решить
            проблему. Статус показывает, что ученика необходимо вызвать на
            беседу с администрацией или отправить на ближайший совет
            профилактики <br /><b>"Вызов родителей: решено":</b> статус
            выставляется, если в школу были вызваны родители ученика, с ними
            проведена беседа. Этот статус является окончательным и не требует
            дальнейшего рассмотрения.
            <br />
            <b>"Трудовое воспитание":</b> статус выставляется, если ученик
            закрыл нарушение общественным трудом (внеочередное дежурство, уборка
            коридора и прочее).

            <br />
            <br />
            Для администрации доступны и другие статусы:
            <br />
            <b>"Проведена беседа":</b> Выставляется администрацией, если
            проведена беседа с участием сотрудника администрации школы, но
            вопрос не требует последующей эскалации. В комментарий указывается
            рекомендации.
            <br />
            <b>"Выговор":</b> Выставляется администрацией, если вынесен
            официальный выговор ребенку. В комментарий обязательно указываются
            реквизиты.
            <br />
            <b>"Решено":</b> Ситуация полностью решена и не требует дальнейшей
            отработки. Желательно использовать такой статус после рассмотрения
            нарушения на совете профилактики.

            <br />
            <br />
            При изменении статуса обязательно заполняйте поле Комментарий! Но не
            удаляйте то, что уже там написано. Просто добавляйте новый текст!
            Таким образом можно отследить предпринятые меры!
          </div>
        </details>
        <div style="width: 100%"></div>
        <details
          v-if="selectedClass.classID != 'violations'"
          style="text-align: auto; width: auto"
        >
          <summary>Количество нарушений на класс</summary>
          <div style="margin: 5px; width: 90%">
            <div
              style="
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
                padding: 10px;
              "
            >
              <div style="width: 200px">
                <b>Класс</b>
                <select
                  class="form-select"
                  v-model="classInShowViolationsByClass"
                  style="width: 190px"
                >
                  <option
                    v-for="(user, index) in classList"
                    :key="index"
                    v-bind:value="user"
                  >
                    {{ user.className }}
                  </option>
                </select>
              </div>
              <div style="padding-left: 40px; margin-top: 22px">
                <button
                  @click="countViolationsByClass()"
                  class="btn btn-primary"
                >
                  Загрузить
                </button>
              </div>
            </div>

            <div
              style="display: flex; justify-content: center; width: min-content"
            >
              <table class="reciept-table" style="cursor: default">
                <tr style="cursor: default">
                  <th>№</th>
                  <th style="min-width: 200px">Фамилия Имя</th>
                  <th>Количество нарушений</th>
                  <th>Не обработано</th>
                  <th>Совет профилактики</th>
                  <th>Трудовое воспитание</th>
                  <th>Вызов родителей: решено</th>
                  <th>Проведена беседа</th>
                  <th>Выговор</th>
                  <th>Решено</th>
                  <th>Посмотреть все нарушения</th>
                </tr>
                <tr
                  v-for="(el, index) in studentListInShowViolationsByClass"
                  :key="index"
                  style="background-color: white; cursor: default"
                >
                  <td style="cursor: default">{{ index + 1 }}</td>
                  <td style="cursor: default">
                    <div>{{ el.FirstName }} {{ el.LastName }}</div>
                  </td>
                  <td style="cursor: default">{{ el.countViolations }}</td>
                  <td
                    :class="{
                      'class-status-BAD': el.countViolationsNotView > 0,
                    }"
                    style="cursor: default"
                  >
                    {{ el.countViolationsNotView }}
                  </td>
                  <td
                    style="cursor: default"
                    :class="{
                      'class-mark-item-council': el.countViolationsСouncil > 0,
                    }"
                  >
                    {{ el.countViolationsСouncil }}
                  </td>
                  <td style="cursor: default">
                    {{ el.countViolationsTrud }}
                  </td>
                  <td style="cursor: default">
                    {{ el.countViolationsParent }}
                  </td>
                  <td style="cursor: default">
                    {{ el.countViolationsBeseda }}
                  </td>
                  <td style="cursor: default">
                    {{ el.countViolationsDemerit }}
                  </td>
                  <td style="cursor: default">
                    {{ el.countViolationsAccept }}
                  </td>
                  <td
                    style="color: blue; cursor: pointer; padding: 0"
                    @click="openViolationsByStudent(el)"
                  >
                    Посмотреть
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </details>
        <div style="width: 100%"></div>
        <!-- создание -->
        <div
          style="
            width: 700px;
            padding: 20px;
            margin: 5px;
            border-radius: 5px;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            -webkit-box-shadow: 4px 4px 8px 0px rgba(0, 72, 172, 0.25);
            -moz-box-shadow: 4px 4px 8px 0px rgba(0, 72, 172, 0.25);
            box-shadow: 4px 4px 8px 0px rgba(0, 72, 172, 0.25);
          "
        >
          <div style="margin: 10px">
            Создание нового нарушения:
            <div>
              <b>Дата:</b>
              <input
                type="date"
                id="Date"
                class="form-control"
                required
                v-model="newViolations.date"
              />
            </div>
            <div>
              <b>Ответственный за информацию</b>
              <input
                type="text"
                id="responsible"
                class="form-control"
                required
                v-model="newViolations.responsible"
              />
            </div>
            <div
              v-for="(el, indexcl) in newViolations.classID"
              :key="indexcl"
              style="display: flex"
            >
              <div style="width: 100px; margin: 3px">
                <b>Класс</b>
                <select
                  class="form-select"
                  id="class"
                  v-model="newViolations.classID[indexcl]"
                  @change="getStudentsList(indexcl)"
                >
                  <option
                    v-for="(user, index) in classList"
                    :key="index"
                    v-bind:value="user"
                  >
                    {{ user.className }}
                  </option>
                </select>
              </div>

              <div style="width: 200px; margin: 3px">
                <b>Ученик</b>
                <select
                  class="form-select"
                  v-model="newViolations.studentID[indexcl]"
                >
                  <!-- @change="changeStudent()" -->
                  <option
                    v-for="user in studentsList[indexcl]"
                    :key="user._id"
                    v-bind:value="user"
                  >
                    {{ user.FirstName }} {{ user.LastName }}
                  </option>
                </select>
              </div>
            </div>
            <v-card-actions id="addStudent">
              <button @click="addStudentNewViol()" class="btn btn-primary">
                Добавить ученика
              </button>
            </v-card-actions>
          </div>

          <div style="margin: 10px">
            <div>
              <b>Группа нарушений:</b>
              <select
                class="form-select"
                id="class"
                v-model="newViolations.group"
              >
                <option>Нарушения дисциплины</option>
                <option>Нарушения учебной деятельности</option>
                <option>Нарушение внешнего вида</option>
                <option>Курение\Алкоголь\Вейп</option>
                <option>Порча школьного имущества</option>
                <option>Прочие нарушения</option>
              </select>
            </div>

            <div style="top: 10px">
              <b>Описание нарушения:</b>
              <textarea
                name=""
                v-model="newViolations.description"
                class="form-control"
                id="exampleFormControlTextarea1"
                rows="5"
              ></textarea>
            </div>
            <v-card-actions id="createNew">
              <button
                @click="createViolations()"
                class="btn btn-success"
                style="width: 250px"
              >
                Создать
              </button>
            </v-card-actions>
          </div>
        </div>
        <!-- карточка нарушения -->
        <v-dialog v-model="showViolationsCart" width="700px">
          <div>
            <div
              style="
                padding: 20px;
                display: flex;
                flex-direction: column;
                border-radius: 5px;
                -webkit-box-shadow: 4px 4px 8px 0px rgba(0, 72, 172, 0.25);
                -moz-box-shadow: 4px 4px 8px 0px rgba(0, 72, 172, 0.25);
                box-shadow: 4px 4px 8px 0px rgba(0, 72, 172, 0.25);
              "
              :class="{
                'class-status-BAD': showViolations.status == 'Не обработано',
                'class-status-accept': showViolations.status == 'Решено',
                'class-mark-item-process':
                  showViolations.status == 'Проведена беседа',
                'class-mark-item-council':
                  showViolations.status == 'Совет профилактики',
                'class-status-demerit': showViolations.status == 'Выговор',
                'class-mark-item-another':
                  showViolations.status != 'Проведена беседа' &&
                  showViolations.status != 'Не обработано' &&
                  showViolations.status != 'Решено' &&
                  showViolations.status != 'Выговор' &&
                  showViolations.status != 'Совет профилактики',
              }"
            >
              <div
                style="
                  background-color: white;
                  border-radius: 5px;
                  padding: 5px;
                "
              >
                <div style="font-size: 16px">
                  <b>Карточка нарушения №{{ showViolations._id }}</b>
                </div>
                <div>
                  <b>Дата:</b>&nbsp;{{
                    new Date(showViolations.date).toLocaleDateString()
                  }}
                </div>
                <div>
                  <b>Ответственный за информацию:</b>&nbsp;{{
                    showViolations.responsible
                  }}
                </div>
                <div>
                  <b>Участники:</b>&nbsp;
                  <div
                    v-for="(el, index) in showViolations.studentID"
                    :key="index"
                  >
                    {{ showViolations.classID[index].className }}
                    {{ el.FirstName }}
                    {{ el.LastName }}
                  </div>
                </div>

                <div><b>Группа:</b>&nbsp;{{ showViolations.group }}</div>
                <div>
                  <b>Описание:</b>&nbsp;{{ showViolations.description }}
                </div>
                <b>Статус:</b>&nbsp; {{ showViolations.status }}
              </div>
              <div>
                <hr />
                <div>
                  <b>Изменение статуса:</b>

                  <select
                    class="form-select"
                    id="class"
                    v-model="showViolations.status"
                  >
                    <option>Не обработано</option>
                    <option>Совет профилактики</option>
                    <option>Вызов родителей: решено</option>
                    <option>Трудовое воспитание</option>
                    <option v-if="selectedClass.classID === 'admin'">
                      Проведена беседа
                    </option>
                    <option v-if="selectedClass.classID === 'admin'">
                      Выговор
                    </option>
                    <option v-if="selectedClass.classID === 'admin'">
                      Решено
                    </option>
                  </select>
                </div>
              </div>
              <div style="top: 10px">
                <b>Комментарий:</b>
                <textarea
                  name=""
                  v-model="showViolations.comment"
                  class="form-control"
                  id="exampleFormControlTextarea1"
                  rows="5"
                ></textarea>
              </div>

              <v-card-actions id="updateViol">
                <button
                  @click="updateViolations(showViolations)"
                  class="btn btn-success"
                  style="width: 100%"
                >
                  Обновить
                </button>
              </v-card-actions>
              <v-card-actions id="del" style="margin: 1px; padding: 1px">
                <div
                  style="width: 100%; display: flex; justify-content: flex-end"
                >
                  <button
                    class="btn btn-danger"
                    @click="showViolationsCart = !showViolationsCart"
                    v-if="selectedClass.classID === 'admin'"
                    style="right: 0px"
                  >
                    Закрыть
                  </button>
                </div>
              </v-card-actions>
            </div>
          </div>
        </v-dialog>
        <!-- Реестр нарушений -->
        <div v-if="selectedClass.classID != 'violations'" style="margin: 5px">
          <div
            style="
              padding: 20px;
              display: flex;
              flex-direction: column;
              border-radius: 5px;
              -webkit-box-shadow: 4px 4px 8px 0px rgba(0, 72, 172, 0.25);
              -moz-box-shadow: 4px 4px 8px 0px rgba(0, 72, 172, 0.25);
              box-shadow: 4px 4px 8px 0px rgba(0, 72, 172, 0.25);
            "
          >
            <b>Реестр нарушений</b> (поиск по таблице "Ctrl+F")
            <div style="width: 200px">
              <button
                style="margin-left: 7px"
                class="btn btn-dark"
                @click="exportData()"
              >
                ЭКСПОРТ ДАННЫХ
              </button>
            </div>

            <!-- hide-default-footer -->
            <v-text-field
              class="search-table"
              v-model="search"
              append-icon="mdi-magnify"
              label="Поиск (по статусу)"
            >
            </v-text-field>
            <v-data-table
              :headers="headers"
              :items="violationsList"
              density="compact"
              @click:row="onClickRow"
              :item-class="row_classes"
              class="table-violations"
              :search="search"
            >
              <template v-slot:[`item.index`]="{ index }">
                {{ index + 1 }}
              </template>
              <template v-slot:[`item.classID`]="{ item }">
                <div v-for="(el, index) in item.classID" :key="index">
                  {{ el.className }}
                </div>
              </template>
              <template v-slot:[`item.description`]="{ item }">
                <div style="width: 200px">
                  {{ item.description }}
                </div>
              </template>
              <template v-slot:[`item.date`]="{ item }">
                {{ new Date(item.date).toLocaleDateString() }}
              </template>
              <template v-slot:[`item.studentID`]="{ item }">
                <div v-for="(el, index) in item.studentID" :key="index">
                  {{ el.FirstName }} {{ el.LastName }}
                </div>
              </template>

              <template v-slot:[`item.status`]="{ item }">
                <div
                  :class="{
                    'class-status-BAD': item.status == 'Не обработано',
                    'class-status-accept': item.status == 'Решено',
                    'class-mark-item-process':
                      item.status == 'Проведена беседа',
                    'class-mark-item-council':
                      item.status == 'Совет профилактики',
                    'class-status-demerit': item.status == 'Выговор',
                    'class-mark-item-another':
                      item.status != 'Проведена беседа' &&
                      item.status != 'Не обработано' &&
                      item.status != 'Решено' &&
                      item.status != 'Выговор' &&
                      item.status != 'Совет профилактики',
                  }"
                  style="
                    padding: 5px;
                    border-radius: 3px;
                    text-align: center;
                    cursor: pointer;
                  "
                  :title="item.comment"
                >
                  {{ item.status }}
                </div>
              </template>
              <template v-slot:[`item.actions`]="{ item }">
                <v-card-actions id="del" style="margin: 1px; padding: 1px">
                  <div>
                    <button
                      class="btn btn-danger"
                      @click="deleteViolations(item._id)"
                      v-if="selectedClass.classID === 'admin'"
                    >
                      Удалить
                    </button>
                  </div>
                </v-card-actions>
              </template>
            </v-data-table>
          </div>
        </div>

        <!-- Просмотр количества нарушений на студента-->

        <v-dialog
          v-model="showViolationsByStudentsCheck"
          style="cursor: default; width: auto; background-color: aliceblue"
        >
          <div style="background-color: white; cursor: default">
            <table class="reciept-table" style="cursor: default">
              <tr>
                <th>№</th>
                <th>Участники</th>
                <th>Дата</th>
                <th>Группа</th>
                <th>Описание</th>
                <th>Статус</th>
                <th>Комментарий</th>
              </tr>
              <tr v-for="(el, index) in showViolationsByStudents" :key="index">
                <td style="cursor: default">{{ index + 1 }}</td>
                <td style="cursor: default">
                  <div v-for="(uch, index2) in el.studentID" :key="index2">
                    {{ el.classID[index2].className }}
                    {{ uch.FirstName }}
                    {{ uch.LastName }}
                  </div>
                </td>
                <td style="cursor: default">
                  {{ new Date(el.date).toLocaleDateString() }}
                </td>
                <td style="cursor: default">{{ el.group }}</td>
                <td style="cursor: default">{{ el.description }}</td>
                <td
                  :class="{
                    'class-status-BAD': el.status == 'Не обработано',
                    'class-status-accept': el.status == 'Решено',
                    'class-mark-item-process': el.status == 'Проведена беседа',
                    'class-mark-item-council':
                      el.status == 'Совет профилактики',
                    'class-status-demerit': el.status == 'Выговор',
                    'class-mark-item-another':
                      el.status != 'Проведена беседа' &&
                      el.status != 'Не обработано' &&
                      el.status != 'Решено' &&
                      el.status != 'Выговор' &&
                      el.status != 'Совет профилактики',
                  }"
                  style="
                    margin: 5px;
                    border-radius: 3px;
                    text-align: center;
                    cursor: pointer;
                  "
                  @click="onClickRow(el)"
                >
                  {{ el.status }}
                </td>
                <td style="cursor: default">{{ el.comment }}</td>
              </tr>
            </table>
          </div>
        </v-dialog>
      </div>
    </div>
  </div>
</template>

<script>
import TutorialDataService from "../services/TutorialDataService";
import * as XLSX from "xlsx";
export default {
  props: {
    selectedClass: Object,
  },
  data() {
    return {
      headers: [
        {
          value: "index",
          text: "№",
          width: "10px",
        },
        { text: "Класс", sortable: false, value: "classID" },
        { text: "ФИ", value: "studentID" },
        { text: "Дата", value: "date" },
        { text: "Группа", value: "group" },
        { text: "Статус", value: "status" },
        { text: "Описание", value: "description" },
        {
          text: "Ответственный за информацию",
          value: "responsible",
          width: "10%",
        },
        { text: "Действия", value: "actions" },
      ],
      classList: [],
      showViolationsByStudents: [],
      showViolationsByStudentsCheck: false,
      classInShowViolationsByClass: "",
      studentListInShowViolationsByClass: "",
      studentsList: [[]],
      search: [],
      showViolationsCart: false,
      violationsList: [
        {
          studentID: ["1231"],
          classID: ["123"],
          responsible: "415",
          description: "15125",
          group: "2131231",
          date: new Date().toISOString().slice(0, 10),
          status: "Не обработано",
          comment: "Пример",
        },
      ],
      showViolations: {
        date: new Date().toISOString().slice(0, 10),
      },
      newViolations: {
        studentID: [""],
        classID: [""],
        responsible: "",
        description: "",
        group: "",
        date: new Date().toISOString().slice(0, 10),
        status: "Не обработано",
        comment: "",
      }, //может быть на весь класс
    };
  },
  methods: {
    openViolationsByStudent(arrID) {
      this.showViolationsByStudentsCheck = !this.showViolationsByStudentsCheck;
      this.showViolationsByStudents = [];
      for (let i = 0; i < this.violationsList.length; i++)
        arrID.violationsID.forEach((el) => {
          if (this.violationsList[i]._id == el) {
            this.showViolationsByStudents.push(this.violationsList[i]);
          }
        });
      console.log(arrID, this.violationsList, this.showViolationsByStudents);

      this.$forceUpdate();
    },
    async countViolationsByClass() {
      let data = {
        classID: this.classInShowViolationsByClass.classID,
        className: this.classInShowViolationsByClass.className,
      };
      console.log(this.classInShowViolationsByClass);
      await TutorialDataService.findStudentByClassID(data)
        .then((response) => {
          console.log(response.data);
          this.studentListInShowViolationsByClass = Object.values(
            response.data
          );
        })
        .catch((e) => {
          console.log(e);
        });

      let tempIDstud = [];
      this.violationsList.forEach((el) => {
        el.studentID.forEach((stud) => {
          tempIDstud.push({
            studentID: stud._id,
            violationsID: el._id,
            violationsStatus: el.status,
          });
        });
      });
      console.log(tempIDstud);

      this.studentListInShowViolationsByClass.forEach((el) => {
        el.countViolations = 0;
        el.countViolationsAccept = 0;
        el.countViolationsNotView = 0;
        el.countViolationsBeseda = 0;
        el.countViolationsСouncil = 0;
        el.countViolationsParent = 0;
        el.countViolationsTrud = 0;
        el.countViolationsDemerit = 0;
        el.violationsID = [];
        tempIDstud.forEach((id) => {
          if (id.studentID == el._id) {
            el.countViolations++;
            el.violationsID.push(id.violationsID);

            if (id.violationsStatus == "Не обработано")
              el.countViolationsNotView++;
            if (id.violationsStatus == "Совет профилактики")
              el.countViolationsСouncil++;
            if (id.violationsStatus == "Вызов родителей: решено")
              el.countViolationsParent++;
            if (id.violationsStatus == "Трудовое воспитание")
              el.countViolationsTrud++;
            if (id.violationsStatus == "Выговор") el.countViolationsDemerit++;
            if (id.violationsStatus == "Решено") el.countViolationsAccept++;
            if (id.violationsStatus == "Проведена беседа")
              el.countViolationsBeseda++;
          }
        });
      });
      this.$forceUpdate();
    },
    row_classes(item) {
      if (item.status == "Не обработано") {
        return "class-status-BAD"; //can also return multiple classes e.g ["orange","disabled"]
      }
    },
    onClickRow(item) {
      console.log(item);
      let data = item;
      this.showViolationsCart = !this.showViolationsCart;
      this.showViolations = data;
    },

    async deleteViolations(id) {
      // let check = false;
      // check = confirm("Вы уверенны?", check);
      let check = prompt(
        "Вы действительно хотите удалить нарушение? Если да, то введите ID нарушение в поле",
        ["ID нарушения"]
      );

      if (check == id) {
        await TutorialDataService.deleteViolations(id)
          .then(() => {
            this.getAllViolations();
            this.showViolationsCart = !this.showViolationsCart;
          })
          .catch((e) => {
            console.log(e);
          });
      }
    },
    addStudentNewViol() {
      this.newViolations.classID.push("");
      this.newViolations.studentID.push("");
      this.studentsList.push([]);
    },

    async updateViolations(item) {
      console.log(item);
      let data = {
        status: this.showViolations.status,
        comment: this.showViolations.comment,
      };
      await TutorialDataService.updateViolations(item._id, data)
        .then((response) => {
          console.log("УСПЕШНО ОТПРАВЛЕНО", response);
          alert("Успешно обновлено!");
        })
        .catch((e) => {
          console.log("1111111111", e);
          alert(" произошла ошибка");
        });
    },
    createViolations() {
      if (
        this.newViolations.responsible.length > 5 &&
        this.newViolations.classID[0].className &&
        this.newViolations.group.length > 3 &&
        this.newViolations.description.length > 3
      ) {
        let data = this.newViolations;
        this.violationsList.push(data);

        this.newViolations = {
          studentID: [""],
          classID: [""],
          responsible: "",
          description: "",
          group: "",
          date: new Date().toISOString().slice(0, 10),
          status: "Не обработано",
          comment: "",
        };
        this.studentList = [];

        TutorialDataService.createViolations(data)
          .then((response) => {
            console.log("УСПЕШНО ОТПРАВЛЕНО", response);

            data = {};
            alert("Нарушение создано");
            this.getAllViolations();
          })
          .catch((e) => {
            console.log("1111111111", e);
          });
      } else {
        alert("Заполните поля!");
      }
    },
    getDisplayClass(data) {
      return {
        classID: data._id,
        className: data.className,
      };
    },
    getDisplayStudent(data) {
      return {
        _id: data._id,
        FirstName: data.FirstName,
        LastName: data.LastName,
        classID: data.classID,
      };
    },
    async getAllClass() {
      await TutorialDataService.getAllCLass()
        .then((response) => {
          console.log(response.data);
          this.classList = response.data.map(this.getDisplayClass);
        })
        .catch((e) => {
          console.log(e);
        });
    },
    async getAllViolations() {
      await TutorialDataService.getAllViolations()
        .then((response) => {
          console.log(response.data);
          this.violationsList = response.data;
        })
        .catch((e) => {
          console.log(e);
        });
    },

    async getStudentsList(index) {
      await TutorialDataService.findStudentByClassID(
        this.newViolations.classID[index]
      ).then((response) => {
        // let students = Object.values(response.data);
        console.log(response.data);
        let temp = [];
        for (let key in response.data) {
          temp.push(response.data[key]);
        }
        console.log(temp, index, this.studentsList);
        this.$set(this.studentsList, index, temp.map(this.getDisplayStudent));
        // students.map((student) => {
        //   if (student != undefined) {
        //     this.studentsList[index].push(student);
        //   }
        // });
      });
    },

    exportData() {
      // let studentsExports = this.students;
      let data = [
        [
          "№",
          "Класс",
          "Ученик(и)",
          "Группа",
          "Статус",
          "Описание",
          "Ответственный за информацию",
          "Комментарий",
        ],
      ];

      for (let i = 0; i < this.violationsList.length; i++) {
        let str = []; // Обнуляем строку для нового объекта
        str.push(i + 1);
        str.push(
          this.violationsList[i].classID
            .map((item) => item.className)
            .join("\n") // Используем стандартный перенос строки
        );
        str.push(
          this.violationsList[i].studentID
            .map((item) => item.FirstName + " " + item.LastName)
            .join("\n") // Используем стандартный перенос строки
        );
        str.push(this.violationsList[i].group);
        str.push(this.violationsList[i].status);
        str.push(this.violationsList[i].description);
        str.push(this.violationsList[i].responsible);
        str.push(this.violationsList[i].comment);

        data.push(str);
      }
      this.exportXLSX(data);
    },
    exportXLSX(ws_data) {
      var wb = XLSX.utils.book_new();
      wb.Props = {
        Title: "SheetJS Tutorial",
        Subject: "Test",
        Author: "Red Stapler",
        CreatedDate: new Date(2017, 12, 19),
      };

      wb.SheetNames.push("Test Sheet");
      var ws = XLSX.utils.aoa_to_sheet(ws_data);

      // Задаем стиль для ячеек с переносами строк
      for (let cell in ws) {
        // Проверяем, что мы работаем с ячейками данных (а не с метаданными)
        if (ws[cell].v && typeof ws[cell].v === "string") {
          ws[cell].s = {
            alignment: {
              wrapText: true, // Разрешаем перенос текста
            },
          };
        }
      }

      wb.Sheets["Test Sheet"] = ws;

      // Генерация файла
      var wbout = XLSX.write(wb, { bookType: "xlsx", type: "binary" });
      function s2ab(s) {
        var buf = new ArrayBuffer(s.length);
        var view = new Uint8Array(buf);
        for (var i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff;
        return buf;
      }

      this.downloadBlob(
        new Blob([s2ab(wbout)], { type: "application/octet-stream" }),
        "Экспорт данных из ACT.xlsx"
      );
    },
    downloadBlob(content, filename, contentType) {
      //   // Create a blob
      //   var blob = new Blob([content], { type: contentType });
      //   var url = URL.createObjectURL(blob);

      //   // Create a link to download it
      //   var pom = document.createElement("a");
      //   pom.href = url;
      //   pom.setAttribute("download", filename);
      //   pom.click();

      var blob = new Blob([content], { type: contentType });
      if (navigator.msSaveBlob) {
        // IE 10+
        navigator.msSaveBlob(blob, filename);
      } else {
        var link = document.createElement("a");
        if (link.download !== undefined) {
          // feature detection
          // Browsers that support HTML5 download attribute
          var url = URL.createObjectURL(blob);
          link.setAttribute("href", url);
          link.setAttribute("download", filename);
          link.style.visibility = "hidden";
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      }
    },
  },

  mounted() {
    this.getAllClass();
    this.getAllViolations();
  },
};
</script>

<style scoped>
.class-status-BAD {
  background-color: rgb(255, 170, 187);
}
.class-status-demerit {
  background-color: rgb(255, 0, 51);
}
.class-mark-item-council {
  background-color: rgb(255, 255, 170);
}

.class-status-accept {
  background-color: rgb(196, 255, 108);
}
.class-mark-item-process {
  background-color: lightblue;
}
.class-mark-item-another {
  background-color: rgb(196, 173, 230);
}

.table-violations,
td,
th {
  cursor: pointer;
}

details {
  border: 1px solid #ccc; /* Обводка элемента */
  border-radius: 4px; /* Сглаженные углы */
  margin: 10px 0; /* Отступы вокруг элемента */
  padding: 10px; /* Внутренний отступ */
  cursor: pointer; /* Указатель мыши */
  background-color: #f9f9f9; /* Фоновый цвет */
  transition: background-color 0.3s; /* Плавный переход фона */
}

details[open] {
  background-color: #e8f0fe; /* Изменение фона при открытом состоянии */
  cursor: default; /* Указатель мыши */
}

summary {
  font-weight: bold; /* Жирный шрифт для заголовка */
}

summary::-webkit-details-marker {
  display: none; /* Скрытие стандартного маркера */
}
</style>
